.mdc-dialog {
  --mdc-dialog-supporting-text-color: black;

  &__surface {
    border-radius: 0.6rem !important;
  }

  &__content {
    padding: 1rem 1.67rem !important;
  }

  &__actions {
    padding: 1rem 1.67rem 1.67rem !important;

    background-color: var(--mat-toolbar-container-background-color);
  }
}

.shadowDialog {
  background: rgba(0, 0, 0, 0.8);
}

.blurryDialog {
  backdrop-filter: blur(2px);
  background: rgba(0, 0, 0, 0.7);
}
