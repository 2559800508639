@use '@angular/material' as mat;
@import '../theme-settings';
$primary: map-get($angular-default-theme, primary);

.mat-mdc-progress-spinner {
  width: 18px !important;
  height: 18px !important;

  @apply mr-0.5;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: transparent !important
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  box-shadow: none !important;
}

.mat-toolbar-row {
  @apply p-0;

  h2 {
    color: mat.get-color-from-palette($primary) !important;
    font-weight: bold !important;
    font-size: 1.34rem !important;
  }
}

.mat-badge-content {
  font-size: 9px !important;
}

.mat-line {
  display: flex;
  align-items: center;
  text-decoration: none !important;
}

.mat-datepicker-dialog {
  background: white !important;
}
