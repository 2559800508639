/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import 'theme.scss';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "../node_modules/angular-calendar/css/angular-calendar.css";

$primary: map-get($angular-default-theme, primary);
$warn: map-get($angular-default-theme, warn);
$accent: map-get($angular-default-theme, accent);

html, body {
  min-height: 100%;
  height: 100%;
  @apply text-[14px] md:text-[15px] lg:text-[16px];
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

a {
  color: mat.get-color-from-palette($primary) !important;
}

@layer components {
  .component-wrapper {
    @apply p-1 block;
  }

  .responsive-form {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-1;
  }

  .table-actions {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-2;

    .left {
      .mat-mdc-form-field {
        @apply w-full lg:w-fit;
      }
    }

    .right {
      @apply flex items-center justify-end;
    }
  }

  fieldset {
    @apply border p-1;
    border-radius: var(--mdc-filled-button-container-shape);

    legend {
      @apply p-1;
      font-weight: 600;
    }
  }
}

// fullcalendar js
.fc-license-message {
  display: none;
}

.fc-day-today {
  background-color: #E8F5E9 !important;
}

.fc-event-main-frame {
  line-height: initial !important;
}

.fc-view-harness {
  min-height: 100vh !important;
}

// material design fixes
.mat-mdc-fab {
  transform: scale(0.7) !important;
}

.additional-info-option {
  .mdc-list-item__primary-text {
    width: 100%;
  }
}

.mat-mdc-snack-bar-label, .mdc-snackbar__surface {
  padding: 0 !important;
}

.mat-mdc-button-touch-target {
  display: none;
}

.mdc-dialog {
  --mdc-dialog-supporting-text-color: black !important;
}

.mat-mdc-form-field-hint {
  display: flex;
  margin-top: 4px;
}

// other adjustments
.tour-step {
  margin: 8px !important;
}

.lightbox-wrapper {
  iframe { position:absolute; }
}

lac-mat-country-selector {
  .country-arrow {
    display: none !important;
  }
}

.data-table {
  width: 100%;
  overflow-x: auto;

  .mat-mdc-row:nth-child(even){
    background-color: #f5f5f5;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

.columns-menu {
  .mat-mdc-menu-content {
    display: flex;
    flex-direction: column;
    padding: 8px 8px;
  }
}

.mdc-notched-outline__notch {
  display: none !important;
}

.spacer {
  flex: 1 1 auto;
}

@import 'styles/typography';
@import 'styles/mdc-fixes';
@import 'styles/dialogs';
